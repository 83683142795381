import * as React from "react";
import { Site } from "tabler-react";

class SiteWrapper extends React.Component {
  render() {
    return (
      <Site.Wrapper
        headerProps={{
          href: "/",
          alt: "Pick Random Winners",
          imageURL: "images/logo.png",
          align: "center"
        }}
        footerProps={{
          copyright: (
            <React.Fragment>
              Copyright © 2020 All rights reserved.
            </React.Fragment>
          )
        }}
      >
        {this.props.children}
      </Site.Wrapper>
    );
  }
}

export default SiteWrapper;
