import React, { Component } from "react";
import { Form, Button, Header } from "tabler-react";

import "./draw-form.styles.css";

class DrawForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemCount: 0
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onChange(e.target);

    let formInputItems = e.target.value;
    let itemList = formInputItems.split("\n");

    itemList = itemList.filter(v => v !== "");
    this.setState({
      itemCount: itemList.length
    });
  }

  render() {
    const {
      style,
      className,
      onSubmit,
      placeholder,
      value,
      drawItems,
      removeDrawnItem,
      disableDrawButton,
      filterDuplicateNames,
      handleFilterDuplicateNamesChange,
      handleRemoveDrawnItemChange,
      handleWinnerCountChange
    } = this.props;

    return (
      <div style={style} className={className}>
        <Header.H3>Random Name Selector</Header.H3>
        <div className="form">
          <Form onSubmit={onSubmit}>
            <Form.FieldSet>
              <p className="noNamesCount">
                {" "}
                Number of names: {this.state.itemCount}
              </p>
              <Form.Group label="Enter your list of names/entries" isRequired>
                <Form.Textarea
                  name="drawItems"
                  placeholder={placeholder}
                  value={value}
                  rows="5"
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group label="Number of names/winners">
                <Form.Select
                  name="winnerCount"
                  onChange={handleWinnerCountChange}
                >
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>5</option>
                  <option>10</option>
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Checkbox
                  name="noRepeat"
                  label="Remove name from list of names after drawing winner"
                  checked={removeDrawnItem}
                  onChange={handleRemoveDrawnItemChange}
                />

                <Form.Checkbox
                  name="noDuplicate"
                  label="Filter duplicate names"
                  checked={filterDuplicateNames}
                  onChange={handleFilterDuplicateNamesChange}
                />
              </Form.Group>

              <Button
                color="primary"
                type="submit"
                disabled={disableDrawButton || drawItems.length < 2}
              >
                {disableDrawButton ? "Drawing..." : "Pick Random Name(s)"}
              </Button>
            </Form.FieldSet>
          </Form>
        </div>
      </div>
    );
  }
}

export default DrawForm;
