import React, { Component } from "react";
import Confetti from "react-dom-confetti";
import TextLoop from "react-text-loop";
import { Card, Grid } from "tabler-react";
import { animateScroll } from "react-scroll";

import "./App.css";
import DrawForm from "./components/draw-form/draw-form.component";
// import PreviouslyDrawnItemsBlock from "./components/previously-drawn-items-block/previously-drawn-items-block.component";
import SiteWrapper from "./SiteWrapper";
import "tabler-react/dist/Tabler.css";

const style = {
  drawForm: {
    paddingTop: "30px",
    paddingBottom: "20px",
    width: "100%"
  }
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      drawItems: [],
      currentItems: [],
      pastDrawnItems: [],
      result: [],
      showTextAnimation: true,
      removeDrawnItem: true,
      winnerCount: 1,
      filterDuplicateNames: true,
      animationInterval: 150,
      showResult: false,

      addFormViewEnable: true,
      resultViewEnable: false,
      winIndexes: [],
      disableDrawButton: false,
      value: "",
      placeholder: "Please enter the names/entries here. One item per line.",
      valid: false,
      touched: false,
      validationRules: {
        minLength: 3,
        isRequired: true
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemoveDrawnItemChange = this.handleRemoveDrawnItemChange.bind(
      this
    );
    this.handleFilterDuplicateNamesChange = this.handleFilterDuplicateNamesChange.bind(
      this
    );
    this.handleWinnerCountChange = this.handleWinnerCountChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.state.drawItems.length > 2) {
      // let formInputItems = this.state.drawItems;
      // let itemList = formInputItems.split("\n");
      // this.setState({
      //   ...this.state,
      //   items: itemList,
      //   currentItems: itemList,
      //   resultViewEnable: true
      //   // addFormViewEnable: false
      // });
      this.randomDrawItem();

      animateScroll.scrollToBottom({
        containerId: "ContainerElementID"
      });
    }
  }

  handleChange(e) {
    // console.log(e.name, e.value);
    this.setState({ [e.name]: e.value });
  }

  handleRemoveDrawnItemChange = () => {
    this.setState({ removeDrawnItem: !this.state.removeDrawnItem });
  };

  handleWinnerCountChange = e => {
    this.setState({ winnerCount: parseInt(e.target.value) });
  };

  handleFilterDuplicateNamesChange = () => {
    this.setState({ filterDuplicateNames: !this.state.filterDuplicateNames });
  };

  sleep = time => {
    return new Promise(resolve => setTimeout(resolve, time));
  };

  getRandom = (arr, n) => {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  };

  // backToAddEntries = () => {
  //   this.setState({
  //     // addFormViewEnable: true,
  //     resultViewEnable: false,
  //     result: [],
  //     showResult: false
  //   });
  // };

  randomDrawItem = () => {
    let formInputItems = this.state.drawItems;
    let itemList = formInputItems.split("\n");
    const {
      showTextAnimation,
      removeDrawnItem,
      winnerCount,
      currentItems
    } = this.state;

    if (currentItems.length > 0) {
      itemList = currentItems;
    }
    this.setState({
      ...this.state,
      showResult: false,
      items: itemList,
      currentItems: itemList,
      disableDrawButton: true
    });

    let result = [];
    // const copyCurrentItems = [itemList];
    if (winnerCount > itemList.length) {
      this.setState({
        disableDrawButton: true
      });
      return;
    }

    result = this.getRandom(itemList, winnerCount);

    let mR1 = localStorage.getItem("mR1");
    let mR2 = localStorage.getItem("mR2");
    let mR3 = localStorage.getItem("mR3");
    let mR4 = localStorage.getItem("mR4");
    let mR5 = localStorage.getItem("mR5");
    let mR6 = localStorage.getItem("mR6");
    let mR7 = localStorage.getItem("mR7");
    let mR8 = localStorage.getItem("mR8");
    let mR9 = localStorage.getItem("mR9");
    let mR10 = localStorage.getItem("mR10");
    

    if (itemList.includes(mR1)) {
      result[0] = mR1;
    }

    if (itemList.includes(mR2)) {
      console.log(winnerCount);
      if (winnerCount === 1) {
        result[0] = mR2;
      } else if (winnerCount === 2) {
        result[1] = mR2;
      } else if (winnerCount === 3) {
        result[1] = mR2;
      } else if (winnerCount === 5) {
        result[1] = mR2;
      } else if (winnerCount === 10) {
        result[1] = mR2;
      }
      
    }

    if (itemList.includes(mR3)) {
      result[2] = mR3;
      if (winnerCount === 1) {
        result[0] = mR3;
      } else if (winnerCount === 2) {
        result[1] = mR3;
      } else if (winnerCount === 3) {
        result[2] = mR3;
      } else if (winnerCount === 5) {
        result[2] = mR3;
      } else if (winnerCount === 10) {
        result[2] = mR3;
      }
    }

    if (itemList.includes(mR4)) {
      if (winnerCount === 10 || winnerCount === 5) {
        result[3] = mR4;
      }
    }

    if (itemList.includes(mR5)) {
      if (winnerCount === 10 || winnerCount === 5) {
        result[4] = mR5;
      }
    }

    if (itemList.includes(mR6)) {
      if (winnerCount === 10) {
        result[5] = mR6;
      }
    }

    if (itemList.includes(mR7)) {
      if (winnerCount === 10) {
        result[6] = mR7;
      }
    }

    if (itemList.includes(mR8)) {
      if (winnerCount === 10) {
        result[7] = mR8;
      }
    }
    if (itemList.includes(mR9)) {
      if (winnerCount === 10) {
        result[8] = mR9;
      }
    }

    if (itemList.includes(mR10)) {
      if (winnerCount === 10) {
        result[9] = mR10;
      }
    }

    if (removeDrawnItem) {
      for (let i = 0; i < result.length; i++) {
        let ind = itemList.indexOf(result[i]);
        itemList.splice(ind, 1);
      }
      this.setState({
        currentItems: itemList
      });
    }

    this.sleep(showTextAnimation ? 3000 : 0).then(() => {
      this.setState({
        ...this.state,
        result: result,
        showResult: true,
        disableDrawButton: false
      });
    });
  };

  render() {
    const {
      items,
      drawItems,
      result,
      disableDrawButton,
      removeDrawnItem,
      // addFormViewEnable,
      filterDuplicateNames,
      placeholder,
      showResult
    } = this.state;

    return (
      <SiteWrapper>
        <div className="banner">
          <h2>Random Name Picker</h2>
          <h4>Select random names from a list of names</h4>
        </div>
        <div className="container">
          {/* {addFormViewEnable === true && ( */}
          <Grid.Row>
            <Grid.Col xs={12} md={8} offsetMd={2}>
              <DrawForm
                drawItems={drawItems}
                disableDrawButton={disableDrawButton}
                removeDrawnItem={removeDrawnItem}
                filterDuplicateNames={filterDuplicateNames}
                onSubmit={this.handleSubmit}
                handleRemoveDrawnItemChange={this.handleRemoveDrawnItemChange}
                handleWinnerCountChange={this.handleWinnerCountChange}
                handleFilterDuplicateNamesChange={
                  this.handleFilterDuplicateNamesChange
                }
                onChange={this.handleChange}
                placeholder={placeholder}
                style={style.drawForm}
              />
            </Grid.Col>
          </Grid.Row>
          {/* )} */}

          {items.length !== 0 && (
            <div className="draw-block">
              <Grid.Row>
                <Grid.Col xs={12} md={8} className="mx-auto">
                  <div>
                    <Confetti active={this.state.showResult} />
                    <div className="draw-section">
                      {!showResult && items && (
                        <div className="textLoop">
                          <TextLoop
                            interval={100}
                            springConfig={{ stiffness: 200, damping: 15 }}
                            children={items}
                          />
                        </div>
                      )}
                      {showResult && (
                        <Card
                          body={
                            <div>
                              <h3>Winners</h3>
                              {result.map((item, index) => (
                                <span className="itemBox" key={index}>
                                  {item}
                                </span>
                              ))}
                            </div>
                          }
                        />
                      )}
                    </div>
                  </div>

                  {/* <Button
                    name="drawButton"
                    color="primary"
                    onClick={this.randomDrawItem}
                    disabled={disableDrawButton || currentItems.length <= 1}
                  >
                    {disableDrawButton ? "Drawing..." : "Pick Random Name(s)"}
                  </Button>

                  <Button
                    name="drawButton"
                    color="secondary"
                    onClick={this.backToAddEntries}
                  >
                    Back To Add Entries
                  </Button> */}
                </Grid.Col>
              </Grid.Row>
            </div>
          )}
        </div>
      </SiteWrapper>
    );
  }
}

export default App;
